import React, {useRef} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import QuestionSection from '../../../../../components/QuestionSection';
import {ConnectorIntro, SeeAllArrow, ConnectorOverview, FeaturesButtons} from '../../../../../components/systems';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Tableau = () => {
  const connectorName = 'tableau';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/tableau/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const images = {
    imageOverview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`),
    imageFeatureOne: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-01`)[0].node,
    imageFeatureTwo: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-02`)[0].node,
    imageFeatureThree: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-03`)[0].node,
    imageFeatureFour: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-04`)[0].node,
    imageLogo: edgesImages.filter((edge) => edge.node.name === `${connectorName}`),
  };

  const connector = {
    intro: {
      logo: images.imageLogo,
      name: 'Tableau',
      description: `Tableau is a powerful business intelligence and data visualization tool that allows users to explore and analyze their data using interactive dashboards, charts, and graphs. Tableau customers can ensure responsible use of data on the platform by using Trustle’s integrated access management and governance features.`,
    },
    buttons: [
      {label: 'View Site Content', ref: null},
      {label: 'View Site Licensing', ref: null},
      {label: 'Manage Access to Content', ref: null},
      {label: 'Report on Usage', ref: null},
    ],
    overview: {
      title: 'Trustle w/ Tableau',
      overview: 'Overview',
      description: `Our integration with <u>Tableau Cloud</u> enables administrators to view all content on the site, what privileges users have to that content, and what licensing is in effect site-wide. This integration provides numerous possibilities for Trustle system owners to better understand their business and make informed decisions. With the Tableau connector, Trustle admins can:<br><br>
<ul class='list-disc ml-8'><li>Gain access to critical information about Tableau users and their level of access to content in Tableau</li>
<li>Efficiently allocate licenses and review user access with the help of Trustle’s Recommendations Engine</li>
<li>Easily manage group memberships and have a full understanding of the permissions associated with each group</li></ul>
At Trustle, we are dedicated to providing our users with the tools they need to better manage and understand their business security. Our integration with Tableau is one example of how we strive to make data analysis more accessible and valuable for our users.`,
    },
  };
  const {intro, buttons, overview} = connector;

  const scrollRefOverview = useRef(null);
  const scrollRefFeature01 = useRef(null);
  const scrollRefFeature02 = useRef(null);
  const scrollRefFeature03 = useRef(null);

  const scrollRefTable = {
    0: scrollRefOverview,
    1: scrollRefFeature01,
    2: scrollRefFeature02,
    3: scrollRefFeature03,
  };

  const handleClick = ({index}) => {
    const barHeight = '80px';
    scrollRefTable[index].current.style.scrollMargin = barHeight;
    scrollRefTable[index].current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <Layout
      seo={
        <Seo
          title="Tableau System | Trustle"
          description="Our integration with Tableau Cloud enables administrators to view all content on the site, what privileges users have to that content, and what licensing is in effect site-wide. This integration provides numerous possibilities for Trustle system owners to better understand their business and make informed decisions."
        />
      }
    >
      <section className="pt-16 overflow-hidden transform bg-gradient-to-b from-gray-200 via-white to-gray-200">
        <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
          <SeeAllArrow />
        </div>

        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center">
          {connector && (
            <>
              <ConnectorIntro name={intro?.name} description={intro?.description} logo={intro?.logo} isPageHeader />
              <FeaturesButtons dataButtons={buttons} handleClick={handleClick} />

              <div ref={scrollRefOverview}></div>
              <ConnectorOverview
                title={overview?.title}
                description={overview?.description}
                image={images?.imageOverview}
              />

              <div ref={scrollRefFeature01}></div>

              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">View Site-wide Content</h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  You can view all projects, workbooks, and sheets on your Tableau Site within Trustle, including the
                  privileges assigned to each of resources. As shown in the picture below, Trustle organizes Tableau
                  content in its native hierarchical state.
                </p>

                <div className="flex flex-col items-center justify-between xl:flex-row">
                  <div className="pb-8 max-w-[604px]">
                    {images?.imageFeatureOne && (
                      <GatsbyImage
                        fluid={images.imageFeatureOne.fluid}
                        alt="featured"
                        image={getImage(images.imageFeatureOne)}
                        className="drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                  <div className="">
                    <div className="max-w-[530px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">Hierarchical Content</h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        View Tableau content as it is laid out on the site, with containment in projects and workbooks.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">View all Permissions and Roles</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        For each piece of content, Trustle displays all the available permissions. You can drill down
                        even further to see which users on the Site have—or have requested—the privilege.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">
                        Make Content Request-able through Trustle
                      </div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        You can set any piece of content to be visible in Trustle’s catalog, so Trustle users can
                        request access to Tableau content. As with all Trustle resources, access can be set to temporary
                        or just-in-time / on-demand.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">View Usage Data and History</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Trustle maintains a history of when content in Tableau is accessed and by whom under what
                        privileges. You can view the report by user or by content. You can print out reports to PDF and
                        CSV formats for future reference and for audit activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col justify-between xl:flex-row ">
                  <div className="pb-8 ">
                    <div className="max-w-[550px]">
                      <h2 className="pt-4 mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">
                        Request Access to Tableau Content
                      </h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Users can browse the available content on the Tableau site and request access and specify which
                        permissions are desired. Based on users’ Role, Group Memberships, and Permissions, access can be
                        granted through the Trustle connector.<br></br> History of access approvals and grants, as well
                        as any usage data are available through the connector’s History tab.
                      </p>
                      <p className="text-left max-w-[240px]">
                        (Note: moving Tableau users from one role to another currently requires a Tableau admin to make
                        the change manually.)
                      </p>
                    </div>
                  </div>
                  <div className="pb-8 max-w-[530px]">
                    {images?.imageFeatureTwo && (
                      <GatsbyImage
                        fluid={images.imageFeatureTwo.fluid}
                        alt="featured"
                        image={getImage(images.imageFeatureTwo)}
                        className="drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div ref={scrollRefFeature02}></div>
              <div className="m-auto mb-16 text-3xl font-bold text-center leading-11 md:leading-12 md:text-5xl text-blue">
                Monitor License Usage in Tableau
              </div>

              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col justify-between xl:flex-row">
                  <div className="pb-8 max-w-[604px]">
                    {images?.imageFeatureThree && (
                      <GatsbyImage
                        fluid={images.imageFeatureThree.fluid}
                        alt="featured"
                        image={getImage(images.imageFeatureThree)}
                        className="drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="max-w-[530px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">Licensing and Roles</h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        In Tableau, each user has a specific role, with all except “Unlicensed User” taking up a license
                        on the site. Using this system, Trustle System Owners and Provisioners and track license usage
                        on their Tableau Sites. Trustle’s recommendation engine also takes into consideration the
                        context of the Tableau role or resource being provisioned. In particular, roles such as Site
                        Admin and Creator are marked as sensitive, so both the approver and Trustle’s workflow policies
                        can have proper context about the request. Similary, Tableau Content Roles, such as Content
                        Owner or Project Leader are treated as medium severity, but Tustle bases recommendations and
                        policies base on the number of users in these roles and other contextual information about the
                        users possessing them.<br></br>
                      </p>

                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        <b>Note:</b>{' '}
                        <i>
                          Trustle currently doesn’t make adjustments to Tableau roles, but will in a future release of
                          this connector. In the meantime, Trustle can manage the approval workflows, but will assign a
                          manual task to a Tableau Administrator to complete the change.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div ref={scrollRefFeature03}></div>
              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <div className="flex flex-col justify-between xl:flex-row ">
                  <div className="pb-8 ">
                    <div className="max-w-[550px]">
                      <h2 className="pt-4 mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">
                        Finding Unused Accounts and Enforcing Security Practices
                      </h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        UTrustle’s Recommendations Engine also helps you find accounts that are licensed, but
                        underutilized or not used at all. You can flag these accounts while you research whether the
                        account is necessary. If the account isn’t needed, you can delete it and save on license count!
                        <br></br>
                        Trustle also identifies accounts that require multi-factor authentication (MFA), SSO
                        authentication, and revoking access for users no longer in the system.
                      </p>
                    </div>
                  </div>
                  <div className="pb-8 max-w-[530px]">
                    {images?.imageFeatureFour && (
                      <GatsbyImage
                        fluid={images.imageFeatureFour.fluid}
                        alt="featured"
                        image={getImage(images.imageFeatureFour)}
                        className="drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <QuestionSection />
    </Layout>
  );
};
export default Tableau;
